import { lightTheme, lightStyleTheme } from './lightTheme'
import { darkTheme, darkStyleTheme } from './darkTheme'


var lightThemeActive: boolean = true;
var styles = lightTheme;
var styleTheme = lightStyleTheme;

if (!lightThemeActive) {
    styles = darkTheme;
    styleTheme = darkStyleTheme;
}


export { styles, styleTheme };
