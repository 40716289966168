
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyALj3KUgF20124p2LRVhNLQQ1wL7Mv8hek",
    authDomain: "jamaican-style-dominoes-3.firebaseapp.com",
    projectId: "jamaican-style-dominoes-3",
    storageBucket: "jamaican-style-dominoes-3.appspot.com",
    messagingSenderId: "311163813639",
    appId: "1:311163813639:web:d5a051d0cc1d77b6d9cfa2",
    measurementId: "G-1N8DZB61M1"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics();


// Initialize Firebase


function logFirebaseEvent(eventName: string, eventMeta: object){
 
    logEvent(analytics, eventName, eventMeta);
}

export {logFirebaseEvent};