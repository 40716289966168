import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, SafeAreaView, Image, Button, TextInput, View } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Login from './Login';
import SignUp from './SignUp';
import Play from './Play';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { styles, styleTheme } from "../themes/styles"
import { storeCache, getCache } from '../utils/cacheStore'
import {serverURL} from '../constants/serverURL'
import UserAccount from './UserAccount';
import PlayerRankings from './PlayerRankings';
import Game from './Game';
import * as notificationSubscriber from "../notification-subscribe";

const Tab = createMaterialTopTabNavigator();


export default class HomeMenu extends Component {
    constructor(props) {
        super(props);
    }

    storeToken(loginResponse) {
        storeCache('token', loginResponse.token)
    };

    handleAuthCheckResponse(response) {
        if (response.status != 200) {
            this.setState({ showForm: true });
            console.log("Auth check failed: ", response);
            response.json().then(data => this.storeToken(""));
            this.props.navigation.navigate('Login', {})
            this.setState({
                errorText: "",
            })
        }
    };

    authCheck() {
        this.setState({ showForm: false });
        getCache("token").then((value: string) => {
            if(value == ''){
                return Promise.reject("do not auth check an empty token")
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({})
            };
            return requestOptions

        }).then((requestOptions) => {
            console.log("Req options: ", requestOptions)
            return fetch(serverURL + '/user/verify/token', requestOptions)
        }).then((response: Response) => {
            this.handleAuthCheckResponse(response)
        }).catch(() => {/* false */
           
        })

    };

    componentDidMount() {
        // var token = getCache('token')
        // if (token != "" && token != undefined) {
            this.authCheck()
        // }
    }

    render() {
        return (
            (<SafeAreaView style={styles.stickToTop}>
                <View style={styles.tabLogoContainer}>
                    <Image style={styles.tabLogo} source={require("../../assets/jsd-logo.png")} />
                </View>
                <Tab.Navigator backBehavior="history"
                    style={styles.homeMenu}
                    barStyle={styles.tabNavContainer}
                    tabBarOptions={styleTheme.tabNavigation}>
                    <Tab.Screen name="Play" component={Play} />
                    <Tab.Screen name="Rankings" component={PlayerRankings} />
                    <Tab.Screen name="Account" component={UserAccount} />
                </Tab.Navigator>
            </SafeAreaView>
            )
        )
    }
}

notificationSubscriber.getPermission();
notificationSubscriber.subscribe();