import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View, Image, Button, Text,Linking, TextInput, Switch } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { Card } from 'react-native-elements';
import LoginForm from './LoginForm'
import { styles, styleTheme } from "../themes/styles";
import { Icon } from 'react-native-elements'
import { storeCache, getCache } from '../utils/cacheStore'
import { serverURL } from '../constants/serverURL'
import { parseJwt } from '../utils/parseToken';
import { SafeAreaView } from 'react-native-safe-area-context';

export default class GameMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: ""
        }
        getCache("token").then((value: string) => {
            var user = parseJwt(value)
            var guest = ""
            if (user.is_guest) {
                guest = " (guest)"
            }
            this.setState({
                username: user.username + guest
            })
        })

    }

    storeToken(loginResponse) {
        storeCache('token', loginResponse.token)
    };

    handleRefreshResponse(response) {
        if (response.status == 200) {
            response.json().then(data => this.storeToken(data));

        } else {
            response.json().then(data => this.storeToken(""));

            this.setState({
                errorText: "",
            })
        }
    };

    refreshToken() {
        this.setState({ showForm: false });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'jsd-auth': getCache("token") },
            body: JSON.stringify({})
        };
        fetch(serverURL + '/user/refresh/token', requestOptions)
            .then(response => this.handleRefreshResponse(response))
    };

    handleLogoutResponse(response) {
        storeCache("token", "")
        this.props.navigation.navigate('Login', {})
    };

    logoutUser() {
        this.setState({ showForm: false });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'jsd-auth': getCache("token") },
            body: JSON.stringify({})
        };
        fetch(serverURL + '/user/logout/', requestOptions)
            .then(response => this.handleLogoutResponse(response))
    };

    componentDidMount(): void {

    }

    quitToMenu() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', 'jsd-auth': getCache("token") },
        };
        fetch(serverURL + '/user/exit/game', requestOptions)
        this.props.navigation.navigate('HomeMenu', {})
    }


    render() {

        return (<SafeAreaView style={styles.container2} >
            <StatusBar style="auto" />
            <Card containerStyle={styles.newGameCard}>
                <Card.Title style={styles.newGameTitle}>Game Menu</Card.Title>
                <Card.Divider />
                <View style={styles.startGameContainer} >
                    <Text style={styles.genericText} > {'Instructions'}</Text>
                    <Text style={styles.instructionsText} > {'\nDouble tap the card you want to play !\n \nIf there are multiple choices,  click on the board where you want the domino to be played.'}</Text>
                    <View style={styles.startGameButton}>
                        <Button
                            onPress={() => this.quitToMenu()}
                            title="Quit to Menu"
                            color={styleTheme.primaryButtonColor}
                            accessibilityLabel="Click here to quit to login" />
                    </View>

                </View>
            </Card>

            <Card containerStyle={styles.newGameCard}>
                <Card.Title style={styles.newGameTitle}>I want to tip the creator of this app</Card.Title>
                <Card.Divider />
                <View style={styles.startGameContainer} >
                    <View style={styles.startGameButton}>
                        <Button
                            onPress={() => Linking.openURL('http://paypal.me/damienhowarddunn')}
                            title="Paypal"
                            color={styleTheme.primaryButtonColor}
                            accessibilityLabel="Send Feedback"
                        />
                    </View>

                    <View style={styles.startGameButton}>
                        <Button
                            onPress={() => Linking.openURL('https://account.venmo.com/u/Damien-Howard-Dunn')}
                            title="Venmo"
                            color={styleTheme.primaryButtonColor}
                            accessibilityLabel="Venmo"
                        />
                    </View>


                    <View style={styles.startGameButton}>
                        <Button
                            onPress={() => Linking.openURL('https://cash.app/$howarddunn95')}
                            title="CashApp"
                            color={styleTheme.primaryButtonColor}
                            accessibilityLabel="Cash App"
                        />
                    </View>

                    <View style={styles.startGameButton}>
                        <Button
                            onPress={() => {
                                window.location.href = "mailto:support@jamaicanstyledominoes.com?subject=AppFeedback&body=message%20goes%20here";
                            }}
                            title="Send Feedback"
                            color={styleTheme.primaryButtonColor}
                            accessibilityLabel="Send Feedback" />
                    </View>
                </View>
            </Card>

        </SafeAreaView>)


    }
}

