import React, { Component } from 'react';
import { ActivityIndicator, CheckBox, Platform, View, Image, Button, Text, TextInput, TouchableHighlight } from 'react-native';
//import CheckBox from '@react-native-community/checkbox';
import { StatusBar } from 'expo-status-bar';
import { styles, styleTheme } from '../themes/styles'
import { darkStyleTheme } from '../themes/darkTheme';
import { storeCache, getCache } from '../utils/cacheStore'
import {serverURL} from '../constants/serverURL'
import { Icon } from 'react-native-elements'

function ValidateEmail(inputText) {
    console.log("Input text: ", inputText);
    var mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (inputText.match(mailformat)) {
        return true;
    }
    else {
        return false;
    }
}

function hasWhiteSpace(s) {
    return s.indexOf(' ') >= 0;
}

export default class SignUpForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fullname: "",
            usernames: "",
            userPassword: "",
            confirmPassword: "",
            userEmail: "",
            showForm: true,
            agree: false,
            passwordStyle: styles.textInputIconStyle,
            passwordPlaceholder: "Password",
            showPassword: false,
            passwordIcon: "eye-slash",
            confirmPasswordStyle: styles.textInputIconStyle,
            confirmPasswordPlaceholder: "Confirm Password",
            showConfirmPassword: false,
            confirmPasswordIcon: "eye-slash",
            errorText: "",
            successText: "",
        }
    };

    componentDidMount() {
        if (getCache('token') != "") {

        }
    }

    setName(text: string) {
        this.setState({
            fullname: text
        });
    };

    setEmail(text: string) {
        this.setState({
            userEmail: text
        });
    };

    setUsername(text: string) {
        this.setState({
            username: text
        });
    };

    setConfirmPassword(text: string) {
        this.setState({
            confirmPassword: text
        });
    };

    setPassword(text: string) {
        this.setState({
            userPassword: text
        });
    };

    storeToken(loginResponse) {
        console.log(Platform.OS)
        storeCache('token', loginResponse.token)

    };

    handleResponse(response) {
        if (response.status == 200) {
            this.setState({ showForm: true });
            console.log("successfully registered");
            this.setState({
                successText: "Successfully created user.",
                errorText: "",
            })
            setTimeout(()=> this.props.navigation.navigate('Login', {}), 5000)
        }
        else if (response.status == 401) {
            console.log("Unable to create user")
            this.setState({ showForm: true });
            this.setState({
                errorText: "Username or Email already registered.",
                successText: "",
            })
            setTimeout(() => this.setState({
                errorText: "",
                successText: "",
            }), 5000)
        } else {
            console.log("Unable to create user")
            this.setState({ showForm: true });
            this.setState({
                errorText: "Username or Email already registered.",
                successText: "",
            })
            setTimeout(() => this.setState({
                errorText: "",
                successText: "",
            }), 5000)
        }
    };

    togglePasswordShow() {
        if (this.state.showPassword) {
            this.setState({ passwordIcon: "eye-slash", showPassword: false })
        } else {
            this.setState({ passwordIcon: "eye", showPassword: true })
        }
    };

    toggleConfirmPasswordShow() {
        if (this.state.showConfirmPassword) {
            this.setState({ confirmPasswordIcon: "eye-slash", showConfirmPassword: false })
        } else {
            this.setState({ confirmPasswordIcon: "eye", showConfirmPassword: true })
        }
    };

    register() {
        if (this.state.userPassword.length < 6) {
            this.setState({
                errorText: "Password too short.",
            })
            return
        }

        if (this.state.userPassword != this.state.confirmPassword) {
            this.setState({
                errorText: "Confirmation password does not match.",
            })
            return
        }

        if (this.state.agree != true) {
            this.setState({
                errorText: "Please agree to the conditions",
            })
            return
        }

        if (ValidateEmail(this.state.username)){
            this.setState({
                errorText: "Please do not use your email as a username",
            })
            return
        }

        if ( hasWhiteSpace(this.state.username)){
            this.setState({
                errorText: "Please do not have a space in your username",
            })
            return
        }

        if ( this.state.username.length > 30){
            this.setState({
                errorText: "Username too long",
            })
            return
        }


        if (!ValidateEmail(this.state.userEmail)){
            this.setState({
                errorText: "Please enter a valid email",
            })
            return
        }

        if (this.state.fullname.trim() == ""){
            this.setState({
                errorText: "Please enter a valid name",
            })
            return
        }
        this.setState({ showForm: false });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                username: this.state.username, password:
                    this.state.userPassword, email: this.state.userEmail, name: this.state.fullname
            })
        };
        fetch(serverURL + '/user/add', requestOptions)
            .then(response => this.handleResponse(response))
    };

    transitionToLogin() {
        this.props.navigation.navigate('Login', { navigation: this.props.navigation })
    };

    transitionToLegacy() {
        window.location.href = 'http://jsdonline.axiomarray.com/sign_up';
    };

    openTermsAndConditions() {

    };

    setToggleCheckBox(value) {
        this.setState({ agree: value })
    };

    renderForm() {

        return (<View>
            <TextInput
                onChangeText={text => this.setName(text)}
                placeholder="Fullname"
                autoCompleteType='name'
                placeholderTextColor={styleTheme.placeholderTextColor}
                style={styles.textInputStyle} />

            <TextInput
                onChangeText={text => this.setUsername(text)}
                placeholder="Username"
                autoCompleteType='username'
                placeholderTextColor={styleTheme.placeholderTextColor}
                style={styles.textInputStyle} />

            <TextInput
                onChangeText={text => this.setEmail(text)}
                placeholder="Email"
                autoCompleteType='email'
                placeholderTextColor={styleTheme.placeholderTextColor}
                style={styles.textInputStyle} />

            <View style={styles.textInputIconContainer}>
                <TextInput
                    onChangeText={text => this.setPassword(text)}
                    style={this.state.passwordStyle}
                    secureTextEntry={!this.state.showPassword}
                    autoCompleteType='password'
                    placeholderTextColor={styleTheme.placeholderTextColor}
                    placeholder={this.state.passwordPlaceholder} />

                <Icon style={styles.textInputIcon} onPress={() => this.togglePasswordShow()}
                    name={this.state.passwordIcon} type='font-awesome' color={styleTheme.placeholderTextColor} />

            </View>

            <View style={styles.textInputIconContainer}>
                <TextInput
                    onChangeText={text => this.setConfirmPassword(text)}
                    style={this.state.confirmPasswordStyle}
                    secureTextEntry={!this.state.showConfirmPassword}
                    autoCompleteType='password'
                    placeholderTextColor={styleTheme.placeholderTextColor}
                    placeholder={this.state.confirmPasswordPlaceholder} />

                <Icon style={styles.textInputIcon} onPress={() => this.toggleConfirmPasswordShow()}
                    name={this.state.confirmPasswordIcon} type='font-awesome' color={styleTheme.placeholderTextColor} />

            </View>

            <View style={styles.textInputIconContainer}>
                <CheckBox
                    disabled={false}
                    value={this.state.agree}
                    onValueChange={(newValue) => this.setToggleCheckBox(newValue)}
                />
                <Text style={styles.genericText}> I agree to the</Text>
                <Text style={styles.buttonText} onPress={() => this.openTermsAndConditions()}>terms and conditions</Text>
            </View>

            <Button
                onPress={() => this.register()}
                title="Register"
                color={styleTheme.primaryButtonColor}
                accessibilityLabel="Click here to register" />

            <View style={styles.signupContainer}>
                <Text style={styles.genericText}>Already have an account ?</Text>
                <Text style={styles.buttonText} onPress={() => this.transitionToLogin()}>Login</Text>
            </View>


            <View style={styles.signupContainer2}>
                <Text style={styles.successText}>{this.state.successText}</Text>
                <Text style={styles.errorText}>{this.state.errorText}</Text>
            </View>
        </View>)
    }

    renderSpinner() {
        return (
            <View style={styles.spinnerContainer}>
                <ActivityIndicator size="large" color={styleTheme.primaryButtonColor} />
            </View>
        )
    }

    render() {

        if (this.state.showForm) {
            return this.renderForm()
        } else {
            return this.renderSpinner()
        }

    }
}
