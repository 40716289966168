import React, { Component, Fragment } from 'react';
import { ActivityIndicator, StyleSheet, View, ScrollView, FlatList, Text, Image, Button, TextInput } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import LoginForm from './LoginForm'
import { styles, styleTheme } from '../themes/styles'
import DominoTable from './DominoTable';
import { storeCache, getCache } from '../utils/cacheStore'
import { serverURL } from '../constants/serverURL'

export default class Play extends Component {


    constructor(props) {
        super(props);
        this.state = {
            gameList: [
                {
                    id: 'default',
                    type: "table",
                },
            ],
            classicGameList: [],
            refresh: false,
        }
    }


    handleGetGameList(response) {
        if (response.status == 200) {
            response.json().then(data => this.updateGameList(data));
        }
    }

    handleGetClassicGameList(response) {
        if (response.status == 200) {
            response.json().then(data => this.updateClassicGameList(data));
        }
    }

    updateGameList(gameList) {
        var defaultTable = [
            {
                id: 'default',
                type: "table",
            },
        ]

        var adsTable = [
            {
                id: 'ad',
                type: 'ad',
            }
        ]
        this.setState({
            gameList: defaultTable.concat(gameList).concat(this.state.classicGameList).concat(adsTable),
            refresh: !this.state.refresh
        })
    }

    updateClassicGameList(gameList) {
        var defaultTable = [];
        this.setState({
            classicGameList: defaultTable.concat(gameList),
        })
    }


    getGameList() {
        // Getting the new golang version
        getCache("token").then((value: string) => {
            if (value == '') {
                return Promise.reject("do not auth check an empty token")
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({})
            };
            return requestOptions

        }).then((requestOptions) => {
            return fetch(serverURL + '/online/list', requestOptions)
        }).then((response: Response) => {
            this.handleGetGameList(response)
        }).catch((error) => {/* false */
            //console.log("error: ", error)
        })


    }

    getClassicList() {
        // Getting the new golang version
        getCache("token").then((value: string) => {
            if (value == '') {
                return Promise.reject("do not auth check an empty token")
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({})
            };
            return requestOptions

        }).then((requestOptions) => {
            return fetch(serverURL + '/online/app/list', requestOptions)
        }).then((response: Response) => {
            this.handleGetClassicGameList(response)
        }).catch((error) => {
        })


    }




    componentDidMount(): void {
        this.getGameList()
        setInterval(() => this.getGameList(), 2000)
    }

    renderTable({ item }, props) {
        return (
            <DominoTable downloadClassicList={this.getClassicList} downloadList={this.getGameList} navigation={props.navigation} item={item} />
        )
    }


    render() {
        return (

            <FlatList
                style={styles.playContent}
                data={this.state.gameList}
                renderItem={(item) => this.renderTable(item, this.props)}
                keyExtractor={item => item.id}
                extraData={this.state.refresh}
                contentContainerStyle={{
                    flexGrow: 1,
                }} />
        )
    }
}

