import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View, Image, Button, Text, FlatList, Linking, TextInput, Switch } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { Card } from 'react-native-elements';
import LoginForm from './LoginForm'
import { styles, styleTheme } from "../themes/styles";
import { Icon } from 'react-native-elements'
import { storeCache, getCache } from '../utils/cacheStore'
import { serverURL } from '../constants/serverURL'
import { parseJwt } from '../utils/parseToken';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import UploadImage from './UploadImage';
import AdComponent from './GoogleAds';
export default class UserAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: "",
            email: "",
            profilePictureURL: null,
            onlinePartnerRoundWins: 0,
            onlinePartnerRounds: 0,
            onlinePartnerGames: 0,
            onlinePartnerWins: 0,
            onlineCutthroatRoundWins: 0,
            onlineCutthroatRounds: 0,
            onlineCutthroatGames: 0,
            onlineCutthroatWins: 0,
        }

        this.getUserInfo();

        getCache("token").then((value: string) => {
            var user = parseJwt(value)
            var guest = ""
            if (user.is_guest) {
                guest = " (guest)"
            }
            this.setState({
                username: user.username + guest,
                bitcoinTitle: "Copy Bitcoin Address"
            })
        })

    }

    storeToken(loginResponse) {
        storeCache('token', loginResponse.token)
    };

    handleRefreshResponse(response) {
        if (response.status == 200) {
            response.json().then(data => this.storeToken(data));

        } else {
            response.json().then(data => this.storeToken(""));
            this.props.navigation.navigate('Login', {})
            this.setState({
                errorText: "",
            })
        }
    };

    refreshToken() {
        this.setState({ showForm: false });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'jsd-auth': getCache("token") },
            body: JSON.stringify({})
        };
        fetch(serverURL + '/user/refresh/token', requestOptions)
            .then(response => this.handleRefreshResponse(response))
    };

    handleLogoutResponse(response) {
        storeCache("token", "")
        this.props.navigation.navigate('Login', {})
    };

    logoutUser() {
        this.setState({ showForm: false });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'jsd-auth': getCache("token") },
            body: JSON.stringify({})
        };
        fetch(serverURL + '/user/logout/', requestOptions)
            .then(response => this.handleLogoutResponse(response))
    };

    handleUserInfoResponse(response) {

        response.json().then(data => {
            this.setState({
                username: data.username,
                email: data.email,
                profilePictureURL: data.profPicURL,
                onlinePartnerRoundWins: data.onlinePartnerRoundWins,
                onlinePartnerRounds: data.onlinePartnerRounds,
                onlinePartnerGames: data.onlinePartnerGames,
                onlinePartnerWins: data.onlinePartnerGameWins,
                onlineCutthroatRoundWins: data.onlineCutthroatRoundWins,
                onlineCutthroatRounds: data.onlineCutthroatRounds,
                onlineCutthroatGames: data.onlineCutthroatGames,
                onlineCutthroatWins: data.onlineCutthroatGameWins,

            })
            console.log("User Info: ", this.state.profilePictureURL);
        });
    }

    getUserInfo(): void {
        getCache("token").then((value: string) => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({})
            };
            fetch(serverURL + '/user/info/', requestOptions)
                .then(response => this.handleUserInfoResponse(response))
        })
    }

    componentDidMount(): void {
        this.getUserInfo()
    }


    renderAccount({ item }, props) {
        if (item == 1) {
            console.log("Account Info: ", item)
            return (
                (<View style={styles.container} >
                    <StatusBar style="auto" />
                    <Card containerStyle={styles.newGameCard}>
                        <Card.Title style={styles.newGameTitle}>Account Information</Card.Title>
                        <Card.Divider />
                        <View style={styles.startGameContainer} >
                            <UploadImage image={this.state.profilePictureURL} />
                            <Text style={styles.genericText2}>{'\nUsername:' + this.state.username + '\n'}</Text>
                            <Text style={styles.genericText2}>{'\nEmail:' + this.state.email + '\n'}</Text>
                            <Text style={styles.genericText2}>{'\nOnline Partner Rounds Played:' + this.state.onlinePartnerRounds + '\n'}</Text>
                            <Text style={styles.genericText2}>{'\nOnline Partner Rounds Won:' + this.state.onlinePartnerRoundWins + '\n'}</Text>
                            <Text style={styles.genericText2}>{'\nOnline Partner Games Played:' + this.state.onlinePartnerGames + '\n'}</Text>
                            <Text style={styles.genericText2}>{'\nOnline Partner Games Won:' + this.state.onlinePartnerWins + '\n'}</Text>
                            <Text style={styles.genericText2}>{'\nOnline Cutthroat Rounds Played:' + this.state.onlineCutthroatRounds + '\n'}</Text>
                            <Text style={styles.genericText2}>{'\nOnline Cutthroat Rounds Won:' + this.state.onlineCutthroatRoundWins + '\n'}</Text>
                            <Text style={styles.genericText2}>{'\nOnline Cutthroat Games Played:' + this.state.onlineCutthroatGames + '\n'}</Text>
                            <Text style={styles.genericText2}>{'\nOnline Cutthroat Games Won:' + this.state.onlineCutthroatWins + '\n'}</Text>

                            <View style={styles.startGameButton}>
                                <Button
                                    onPress={() => this.logoutUser()}
                                    title="Logout User"
                                    color={styleTheme.primaryButtonColor}
                                    accessibilityLabel="Click here to login" />
                            </View>

                        </View>
                    </Card>

                </View>)
            )
        }
        return (
        <Card containerStyle={styles.adCard}>
            <AdComponent></AdComponent>
        </Card>
        )

    }


    render() {
        var now = performance.now();

        return (
            <FlatList
                style={styles.playContent}
                data={[1, 2]}
                renderItem={(item) => this.renderAccount(item, this.props)}
                contentContainerStyle={{
                    flexGrow: 1,
                }} />
        )
    }
}

