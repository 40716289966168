import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View, Image, Button, Text, Linking, TextInput, Switch } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { Card } from 'react-native-elements';
import LoginForm from './LoginForm'
import { styles, styleTheme } from "../themes/styles";
import { Icon } from 'react-native-elements'
import { storeCache, getCache } from '../utils/cacheStore'
import { serverURL } from '../constants/serverURL'
import { parseJwt } from '../utils/parseToken';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function ordinal_suffix_of(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

export default class PlayerRank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: ""
        }
        getCache("token").then((value: string) => {
            var user = parseJwt(value)
            var guest = ""
            if (user.is_guest) {
                guest = " (guest)"
            }
            this.setState({
                username: user.username + guest,
                bitcoinTitle: "Copy Bitcoin Address"
            })
        })

    }

    storeToken(loginResponse) {
        storeCache('token', loginResponse.token)
    };

    handleRefreshResponse(response) {
        if (response.status == 200) {
            response.json().then(data => this.storeToken(data));

        } else {
            response.json().then(data => this.storeToken(""));
            this.props.navigation.navigate('Login', {})
            this.setState({
                errorText: "",
            })
        }
    };

    refreshToken() {
        this.setState({ showForm: false });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'jsd-auth': getCache("token") },
            body: JSON.stringify({})
        };
        fetch(serverURL + '/user/refresh/token', requestOptions)
            .then(response => this.handleRefreshResponse(response))
    };

    handleLogoutResponse(response) {
        storeCache("token", "")
        this.props.navigation.navigate('Login', {})
    };

    logoutUser() {
        this.setState({ showForm: false });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'jsd-auth': getCache("token") },
            body: JSON.stringify({})
        };
        fetch(serverURL + '/user/logout/', requestOptions)
            .then(response => this.handleLogoutResponse(response))
    };

    componentDidMount(): void {

    }


    render() {
        return (<View style={styles.container} >
            <StatusBar style="auto" />
            <Card containerStyle={styles.newGameCard}>
                <View style={styles.startGameContainer} >
                    <Image
                        style={styles.profPic}
                        source={{
                            uri: this.props.item.profPicSource,
                        }}
                    />
                    <Text style={styles.genericText}>{'\n' + this.props.item.username + '\n'}</Text>
                    
                    <Text style={styles.genericText}>{'\nScore:' + Math.round(this.props.item.eloScore) + '\n'}</Text>

                    <Text style={styles.genericText} >{'\n' + ordinal_suffix_of(this.props.item.position)  + '\n'}</Text>
                </View>
            </Card>

        </View>)


    }
}