import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View, Image, Button, TextInput } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import SignUpForm from './SignUpForm'
import {styles, styleTheme} from "../themes/styles"

export default class SignUp extends Component {
  render() {
    return (<View style={styles.container} >

      <View style={styles.logoContainer}>
        <Image style={styles.logo} source={require("../../assets/jsd-logo.png")} />
      </View>

      <View style={styles.signupFormContainer}>
          <SignUpForm navigation={this.props.navigation}></SignUpForm>
      </View>
      
      <StatusBar style="auto" />
    </View>)
  }
}

