import { serverURL } from './constants/serverURL';
import { logFirebaseEvent } from './utils/firebase';
import { getCache } from './utils/cacheStore'


const publicVapidKey = 'BE3ihH1nmjwvG429MO8DQxnRnnyZMg0ImBGxY4LojYMD86H3qjOB94T6IhZsCtER3EptkUkHJNeJodTV94xYYW0';

// Copied from the web-push documentation
const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

export function getPermission() {
    // Let's check if the browser supports notifications
    if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
        logFirebaseEvent( 'notification_not_supported', {});
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied' || Notification.permission === "default") {
        Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
            if (permission === "granted") {
                logFirebaseEvent('notification_permission_granted', {});
            } else {
                logFirebaseEvent('notification_permission_denied', {});
            }
        });
    }

    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them any more.
}


export async function subscribe() {
    if (!('serviceWorker' in navigator)) {
        console.log("Service worker not registered")
        return;
    }

    const registration = await navigator.serviceWorker.ready;
    console.log("Service worker ready: ", subscribeURL, subscription)
    // Subscribe to push notifications
    const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
    });

    var subscribeURL = serverURL + '/user/subscribe';
    console.log("Subscribing to notifications: ", subscribeURL, subscription)

    await getCache("token").then((value) => {
        if (value == '') {
            return Promise.reject("do not auth check an empty token")
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
            body: JSON.stringify(subscription)
        };
        return requestOptions

    }).then((requestOptions) => {
        console.log("Req options: ", requestOptions)
        return fetch(subscribeURL, requestOptions)
    }).then((response) => {
        
    }).catch(() => {/* false */
        console.log("Error subscribing to notifcations")
    });
    console.log("Finish trying to subcscribe to notifications")
};