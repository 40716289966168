import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { Platform, StyleSheet, Text } from 'react-native';
import Login from './src/components/Login';
import SignUp from './src/components/SignUp';
import HomeMenu from './src/components/HomeMenu';
import GameScreen from './src/components/GameScreen';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import ChangePassword from './src/components/ChangePassword';
import PasswordReset from './src/components/PasswordReset';
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import { ReactNotifications } from 'react-notifications-component'
import { startFileServer } from './src/utils/localServer';

// if (Platform.OS == "android" || Platform.OS == "ios"){
//   startFileServer();
// }

const Stack = createStackNavigator();

const config = {
  screens: {
    PasswordReset: 'forgot-password',
    Login: 'login',
    SignUp: 'register',
    ChangePassword: 'change-password/:key'
  },
};

const linking = {
  prefixes: ['https://jamaicanstyledominoes.com',],
  config,
};

export default function App() {
  return (
    <SafeAreaProvider>
      <ReactNotifications />
      <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
        <Stack.Navigator screenOptions={{
          headerShown: false
        }}>
          <Stack.Screen
            name="Login"
            component={Login}
          />

          <Stack.Screen
            name="GameScreen"
            component={GameScreen}
          />

          <Stack.Screen
            name="SignUp"
            component={SignUp}
          />

          <Stack.Screen
            name="HomeMenu"
            component={HomeMenu}
          />

          <Stack.Screen
            name="PasswordReset"
            component={PasswordReset}
          />

          <Stack.Screen
            name="ChangePassword"
            component={ChangePassword}
          />

        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaProvider>
    
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

serviceWorkerRegistration.register();
