import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View, Image, Button, TextInput, Text, Modal, Alert, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import { Card } from 'react-native-elements';
import { StatusBar } from 'expo-status-bar';
import LoginForm from './LoginForm'
import { styles, styleTheme } from "../themes/styles"
import StartGameMenu from './StartGameMenu';
import { Icon } from 'react-native-elements'


const users = [
    {
        name: 'brynn',
        avatar: 'https://cdn.britannica.com/45/18445-050-59915B6F/Dominoes.jpg'
    },
]

export default class NewGameCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startGameVisible: false
        }
    }

    toggleNewGameModal() {
        this.setState({
            startGameVisible: !this.state.startGameVisible
        });
    };

    render() {
        return (


            <View style={styles.container} >


                <Card containerStyle={styles.newGameCard}>
                    <Card.Title style={styles.newGameTitle}>Start New Game</Card.Title>
                    <Card.Divider />
                    <View style={styles.newGame}>
                        <Text style={styles.newGameText}>Practice vs the computer AI or see if you can manage the real yardie dem playing online.</Text>

                    </View>
                    <View>
                    <StartGameMenu navigation={this.props.navigation}></StartGameMenu>
                    </View> 
                </Card>
                <StatusBar style="auto" />
            </View>

        )
    }
}

