import React, { Component } from 'react';
import { ActivityIndicator, Platform, StyleSheet, View, Image, Button, Text, TextInput, TouchableHighlight } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { styles, styleTheme } from '../themes/styles'
import { darkStyleTheme } from '../themes/darkTheme';
import { storeCache, getCache } from '../utils/cacheStore'
import { serverURL } from '../constants/serverURL'
import { Icon } from 'react-native-elements'
import { fetchWithTimeout } from './fetchWithTimeout'
import { logFirebaseEvent } from '../utils/firebase';

export default class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userIdentity: "",
            userPassword: "",
            showForm: true,
            passwordStyle: styles.textInputIconStyle,
            passwordPlaceholder: "Password",
            showPassword: false,
            passwordIcon: "eye-slash",
            errorText: "",
        }
    };

    setUsername(text: string) {
        this.setState({
            userIdentity: text
        });
    };

    setPassword(text: string) {
        this.setState({
            userPassword: text
        });
    };

    storeToken(loginResponse) {
        storeCache('token', loginResponse.token)
    };

    handleResponse(response) {
        if (response.status == 200) {
            this.setState({ showForm: true });
            console.log("successfully logged in");
            response.json().then(data => {
                this.storeToken(data);
                this.props.navigation.navigate('HomeMenu', {})
            }      
            );
            
            this.setState({
                errorText: "",
            })
        }
        else if (response.status == 401) {
            console.log("Incorrect credentials")
            this.setState({ showForm: true });
            this.setState({
                errorText: "Please check your login credentials",
            })
            setTimeout(() => this.setState({
                errorText: "",
            }), 5000)
            logFirebaseEvent( 'user_unauthorized', { });
        }
    };


    togglePasswordShow() {

        if (this.state.showPassword) {
            this.setState({ passwordIcon: "eye-slash", showPassword: false })
        } else {
            this.setState({ passwordIcon: "eye", showPassword: true })
        }
    };

    handleAuthCheckResponse(response) {
        if (response.status == 200) {
            this.setState({ showForm: true });
            console.log("successfully logged in");
            response.json().then(data => this.storeToken(data));
            this.props.navigation.navigate('HomeMenu', {})
            this.setState({
                errorText: "",
            })
        } else {
            this.setState({ showForm: true });
        }

    };

    authCheck() {
        this.setState({ showForm: false });
        getCache("token").then((value: string) => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({})
            };
            return requestOptions

        }).then((requestOptions) => {
            console.log("Req options: ", requestOptions)
            return fetch(serverURL + '/user/verify/token', requestOptions)
        }).then((response: Response) => {
            this.handleAuthCheckResponse(response)
        })

    };

    login() {
        this.setState({ showForm: false });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ identifier: this.state.userIdentity, password: this.state.userPassword })
        };
        fetchWithTimeout(serverURL + '/user/login', requestOptions,)
            .then(response => this.handleResponse(response)).catch((e) => {/* false */
                this.setState({ showForm: true });
                this.setState({
                    errorText: "Sorry, unable to login at the moment",
                })
                setTimeout(() => this.setState({
                    errorText: "",
                }), 5000)
                logFirebaseEvent('login_timeout', { });
            })
    };

    transitionToSignUp() {
        this.props.navigation.navigate('SignUp', {})
    };

    guestLogin() {
        this.setState({ showForm: false });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({})
        };
        fetchWithTimeout(serverURL + '/user/be-guest', requestOptions)
            .then(response => this.handleResponse(response))
            .catch((e) => {/* false */
                this.setState({ showForm: true });
                this.setState({
                    errorText: "Sorry, unable to login at the moment, allowing offline play",
                })
                setTimeout(() => this.setState({
                    errorText: "",
                }), 5000)
                setTimeout(() => this.props.navigation.navigate('HomeMenu', {}), 3000);
                logFirebaseEvent( 'login_timeout_guest', { });
            })
    }

    forgotPassword() {
        this.props.navigation.navigate('PasswordReset', {})
    }

    transitionToPlay() {
        var gameMode = "offline-cutthroat"
        if (Platform.OS == "web") {
            window.location.href = '/play?gameMode=' + gameMode;
        } else {
            this.props.navigation.navigate('Game', { gameMode: gameMode })
        }
    };

    componentDidMount() {

        this.authCheck()

    }

    renderForm() {
        return (<View>
            <TextInput
                onChangeText={text => this.setUsername(text)}
                placeholder="Email or Username"
                autoCompleteType='username'
                placeholderTextColor={styleTheme.placeholderTextColor}
                style={styles.textInputStyle} />

            <View style={styles.textInputIconContainer}>
                <TextInput
                    onChangeText={text => this.setPassword(text)}
                    style={this.state.passwordStyle}
                    autoCompleteType='password'
                    secureTextEntry={!this.state.showPassword}
                    placeholderTextColor={styleTheme.placeholderTextColor}
                    placeholder={this.state.passwordPlaceholder} />

                <Icon style={styles.textInputIcon} onPress={() => this.togglePasswordShow()}
                    name={this.state.passwordIcon} type='font-awesome' color={styleTheme.placeholderTextColor} />

            </View>

            <Button
                onPress={() => this.login()}
                title="Sign In"
                color={styleTheme.primaryButtonColor}
                accessibilityLabel="Click here to login" />

            <View style={styles.signupContainer}>
                <Text style={styles.genericText}>Don't have an account ?</Text>
                <Text style={styles.buttonText} onPress={() => this.transitionToSignUp()}>Register</Text>
            </View>

            <View style={styles.signupContainer2}>
                <Text style={styles.genericText}>Skip the login</Text>
                <Text style={styles.buttonText} onPress={() => this.guestLogin()}>Play!</Text>
            </View>

            <View style={styles.signupContainer2}>
                <Text style={styles.genericText}>Forgot password</Text>
                <Text style={styles.buttonText} onPress={() => this.forgotPassword()}>Reset password</Text>
            </View>

            <View style={styles.signupContainer2}>
                <Text style={styles.errorText}>{this.state.errorText}</Text>
            </View>

        </View>)
    }

    renderSpinner() {
        return (
            <View style={styles.spinnerContainer}>
                <ActivityIndicator size="large" color={styleTheme.primaryButtonColor} />
            </View>
        )
    }

    render() {

        if (this.state.showForm) {
            return this.renderForm()
        } else {
            return this.renderSpinner()
        }

    }
}
