import React, { Component } from 'react';
import { Platform, StyleSheet, Text, View } from 'react-native';
import { WebView } from 'react-native-webview';
import { SafeAreaProvider} from 'react-native-safe-area-context';
import {StatusBar} from 'react-native';
import { serverURL, feUrl } from '../constants/serverURL'
var htmlContent = require('../game/game.html');
// ...

function getHeight() {
    if (Platform.OS  ===  "android"){
        return StatusBar.currentHeight
    } 
    return 30
}  
export default class Game extends Component {
    componentDidMount(): void {
        console.log(this.props)
    }

    onNavigationStateChange(navState) {
        this.setState({
          canGoBack: navState.canGoBack
        });
    }


    render() {

        const data = 'lorem <b>ipsum</b>';

        return (

        <iframe  width="100%" 
        height="100%"  src="/_play"></iframe>
    
        )
        
    }
}