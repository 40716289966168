import { StyleSheet, } from 'react-native';
import { withTheme } from 'react-native-elements';
import { color } from 'react-native-elements/dist/helpers';

const lightTheme = StyleSheet.create({
    closeModalIcon: {
        alignSelf: 'flex-end',
        position: 'absolute',
        top: 0,
        right: 0,
    },

    titleText: {

        fontSize: 20,
    },

    modalView: {
        height: 300,
    },

    startGameDifficulty: {
        flexDirection: 'row',
        width: 250,
        alignItems: 'center',
        justifyContent: 'center',

    },

    startGameButton: {
        width: 250,
        margin: 10
    },

    startGameContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    dominoBorder: {
        borderRightColor: '#D3D3D3',
        borderRightWidth: 1,
    },
    dominoPlayerText: {
        color: "#000000",
        fontSize: 8,
        width: '105%',
        textAlign: 'center',
        marginBottom: 10,

    },
    instructionsText: {
        color: "#000000",
        fontSize: 10,
        width: '105%',
        textAlign: 'center',
        marginBottom: 10,

    },
    dominoPlayerTextJoin: {
        color: "#000000",
        fontSize: 8,
        width: '105%',
        textAlign: 'center',
        marginBottom: 10,
        fontStyle: 'italic',
    },
    dominoScoreText: {
        color: "#000000",
        fontSize: 24,
        width: '105%',
        textAlign: 'center',
        marginBottom: 10,
    },
    dominoPlayer: {
        width: 50,
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        marginLeft: '1%',
        marginRight: '1%',
    },
    dominoPlayerPic: {
        resizeMode: 'contain',
        width: 50,
        height: 50,
        borderRadius: 50 / 2,
        marginBottom: 10,
    },
    dominoPlayerButton: {
        width: 60,
        margin: 5,
        borderRadius: 10,
    },
    dominoTableHolder: {
        flexDirection: 'row'
    },
    dominoTableHolderButton: {
        flexDirection: 'row'
    },
    playButton: {
        marginTop: 10,
        width: '100%',
        justifyContent: 'center',
    },
    modalOverlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    stickToTop: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "#ffffff",
    },
    adCard: {
        marginHorizontal: '10%',
        width: '80vw',
    },
    newGameCard: {
        marginHorizontal: '10%',
    },
    dominoTable: {
        marginHorizontal: '10%',
    },
    newGameTitle: {

    },
    newGameText: {
        marginBottom: 20,
        textAlign: 'center',
    },
    newGame: {},
    textInputIcon: {
        height: 40,
        width: 50,
        backgroundColor: 'rgba(220,220,220,0.2)',
        paddingTop: 6
    },
    textInputIconContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
    },
    spinnerContainer: {
        marginTop: 40,
    },
    logo: {
        resizeMode: 'contain',
        width: 250,
        height: 100,
    },
    primaryButtonStyle: {

    },
    textInputIconStyle: {
        height: 40,
        width: 200,
        backgroundColor: 'rgba(220,220,220,0.2)',
    },
    textInputStyle: {
        height: 40,
        width: 250,
        marginBottom: 20,
        backgroundColor: 'rgba(220,220,220,0.2)',
    },
    incorrectPasswordStyle: {
        height: 40,
        width: 250,
        marginBottom: 20,
        backgroundColor: 'rgba(252,213,206,0.3)',
    },
    signupContainer: {
        flexDirection: 'row',
        flex: 1,
        marginTop: 30,
        marginBottom: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    signupContainer2: {
        flexDirection: 'row',
        flex: 1,
        marginTop: 5,
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    formContainer: {
        marginTop: 20,
        alignItems: 'center',
        justifyContent: 'flex-start',
        flex: 6
    },
    signupFormContainer: {
        marginTop: 20,
        alignItems: 'center',
        justifyContent: 'flex-start',
        flex: 12
    },
    logoContainer: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: 5
    },
    tabLogoContainer: {
        alignItems: 'center',
        backgroundColor: 'white',
    },
    profPic: {
        resizeMode: 'contain',
        width: 100,
        height: 100,
        borderRadius: 100 / 2
    },
    adIframe:{

    },
    tabLogo: {
        resizeMode: 'contain',
        width: 125,
        height: 50,
    },
    tabLogo2: {
        resizeMode: 'contain',
        width: 64,
        height: 25,
    },
    labelText: {
        textAlign: 'left',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        margin: 0,
        padding: 0,
    },
    tabNavContainer: {


    },
    homeMenu: {
        backgroundColor: 'rgba(255,255,255,1.0)',

    },
    playContent: {
        paddingBottom: "5%",

    },
    container2: {
        flex: 1,
        backgroundColor: "#ffffff",
    },
    container: {
        flex: 1,
    },
    genericText: {
        color: "#000000",
        marginRight: 5,
    },
    genericText2: {
        color: "#000000",
        marginRight: 5, 
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    buttonText: {
        color: "#268529"
    },
    errorText: {
        color: "#8E1600"
    },
    successText: {
        color: "#268529"
    },




    // container2: {
    //     flex: 1,
    //     marginTop: StatusBar.currentHeight || 0,
    //   },
    item: {
        backgroundColor: '#f9c2ff',
        padding: 20,
        marginVertical: 8,
        marginHorizontal: 16,
    },
    title: {
        fontSize: 32,
    },
});

const lightStyleTheme = {

    placeholderTextColor: "#acacac",
    primaryButtonColor: "#268529",
    tabNavigation: {
        indicatorStyle: { backgroundColor: "#268529" },
        innerHeight: 500,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },


}

export { lightTheme, lightStyleTheme };