import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View, Image, Button, TextInput } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import ChangePasswordForm from './ChangePasswordForm'
import { styles, styleTheme } from "../themes/styles"

export default class ChangePassword extends Component {

    componentDidMount(): void {
        console.log(this.props.route.params.key)
    }
    render() {
        return (<View style={styles.container} >

            <View style={styles.logoContainer}>
                <Image style={styles.logo} source={require("../../assets/jsd-logo.png")} />
            </View>

            <View style={styles.signupFormContainer}>
                <ChangePasswordForm activation_key={this.props.route.params.key} navigation={this.props.navigation}></ChangePasswordForm>
            </View>

            <StatusBar style="auto" />
        </View>)
    }
}

