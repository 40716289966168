import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View, Image, Button, Text, Switch, Platform } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { Card } from 'react-native-elements';
import LoginForm from './LoginForm'
import { styles, styleTheme } from "../themes/styles";
import { Icon } from 'react-native-elements'
import { storeCache, getCache } from '../utils/cacheStore'
import { serverURL, serverWSURL } from '../constants/serverURL'
import NewGameCard from './NewGameCard';
import { Divider } from '@rneui/themed';
import { parseJwt } from '../utils/parseToken';
import AdSense from 'react-adsense';
import { logFirebaseEvent } from '../utils/firebase';
import AdComponent from './GoogleAds'

const now = performance.now();


export default class DominoTable extends Component {
    constructor(props) {
        super(props);




        var nativeAd = (

            <Card containerStyle={styles.adCard}>
                {/* <div id={ad.id}></div> */}
                <AdComponent></AdComponent>
            </Card>
        )

        if (Platform.OS != "web") {
            nativeAd = (
                <View></View>
            )
        }


        this.state = {
            difficultyHard: false,
            errorText: "",
            username: "",
            adId: "",
            adSrc: "",
            ad: nativeAd,
        }
        getCache("token").then((value: string) => {
            var user = parseJwt(value)
            var guest = ""
            if (user.is_guest) {
                guest = " (guest)"
            }
            this.setState({
                username: user.username
            })
        })
    }

    handleGameInteraction(response) {
        this.props.downloadList()
        setTimeout(() => {
            this.props.downloadList()
        }, 2000);
    }


    handleCreateGameResponse(response) {
        if (response.status == 200) {

        } else if (response.status == 400) {
            this.setState({
                errorText: "Please Join the Game Below",
            })
            setTimeout(() => this.setState({
                errorText: "",
            }), 5000)
        }
        else {
            this.setState({
                errorText: "Error Creating game, please check your connection",
            })
            setTimeout(() => this.setState({
                errorText: "",
            }), 5000)
        }

    };

    spectateGame(gameID: string, gameMode: string) {
        console.log("Platform: ", Platform.OS)
        if (Platform.OS == "web") {
            storeCache("connectionType", "online");
            storeCache("gameURL", serverWSURL + "/online/spectate/" + gameID);
            storeCache("randomSeed", 0);
            storeCache("dominoGameMode", gameMode);
            storeCache("actionType", "spectate");
            logFirebaseEvent('spectate_game_online', { dominoGameMode: gameMode, connectionType: "online" });
            this.props.navigation.navigate('GameScreen', { gameMode: gameMode, online: true, gameID: gameID, classicMode: false });
            // window.location.href = '/play';
        } else {
            //this.props.navigation.navigate('Game', { gameMode: gameMode})
        }
    }

    playGame(gameID: string, gameMode: string) {
        console.log("Platform: ", Platform.OS)
        if (Platform.OS == "web") {
            storeCache("connectionType", "online")
            storeCache("gameURL", serverWSURL + "/online/play/" + gameID)
            storeCache("randomSeed", 0)
            storeCache("dominoGameMode", gameMode)
            storeCache("actionType", "play")
            logFirebaseEvent('play_game_online', { dominoGameMode: gameMode, connectionType: "online" });
            this.props.navigation.navigate('GameScreen', { gameMode: gameMode, online: true, gameID: gameID, classicMode: false })

            //window.location.href = '/play';
        } else {
            //this.props.navigation.navigate('Game', { gameMode: gameMode})
        }
    }

    spectateClassicGame(gameID: string, gameMode: string) {
        console.log("Platform: ", Platform.OS)
        if (Platform.OS == "web") {
            storeCache("connectionType", "online");
            storeCache("gameURL", serverWSURL + "/online/app/spectate/" + gameMode + "/" + gameID);
            storeCache("randomSeed", 0);
            storeCache("dominoGameMode", gameMode);
            storeCache("actionType", "spectate");
            logFirebaseEvent('spectate_classic_game_online', { dominoGameMode: gameMode, connectionType: "online" });
            this.props.navigation.navigate('GameScreen', { gameMode: gameMode, online: true, gameID: gameID, classicMode: true });
            // window.location.href = '/play';
        } else {
            //this.props.navigation.navigate('Game', { gameMode: gameMode})
        }
    }

    playClassicGame(gameID: string, gameMode: string) {
        console.log("Platform: ", Platform.OS)
        if (Platform.OS == "web") {
            storeCache("connectionType", "online")
            storeCache("gameURL", serverWSURL + "/online/app/play/" + gameMode + "/" + gameID)
            storeCache("randomSeed", 0)
            storeCache("dominoGameMode", gameMode)
            storeCache("actionType", "play")
            logFirebaseEvent('play_classic_game_online', { dominoGameMode: gameMode, connectionType: "online" });
            this.props.navigation.navigate('GameScreen', { gameMode: gameMode, online: true, gameID: gameID, classicMode: true })

            //window.location.href = '/play';
        } else {
            //this.props.navigation.navigate('Game', { gameMode: gameMode})
        }
    }

    createGame(gameType: string) {
        this.setState({ showForm: false });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'jsd-auth': getCache("token") },
            body: JSON.stringify({ "type": gameType, "isPrivate": false })
        };
        fetch(serverURL + '/online/create', requestOptions)
            .then(response => this.handleCreateGameResponse(response))
        logFirebaseEvent('create_game', { dominoGameMode: gameType });
        // TODO: Load AD then load modal
    };

    joinGame(gameId: string, position: number) {
        getCache("token").then((value: string) => {
            if (value == '') {
                return Promise.reject("do not auth check an empty token")
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({ "gameId": gameId, "position": position })
            };
            return requestOptions

        }).then((requestOptions) => {
            console.log("Req options: ", requestOptions)
            return fetch(serverURL + '/online/join', requestOptions)
        }).then((response: Response) => {
            this.handleGameInteraction(response)
        }).catch(() => {/* false */

        })
        logFirebaseEvent('join_game', { gameID: gameId, position: position });
    };

    leaveGame(gameId: string) {
        getCache("token").then((value: string) => {
            if (value == '') {
                return Promise.reject("do not auth check an empty token")
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({ "gameId": gameId })
            };
            return requestOptions

        }).then((requestOptions) => {
            console.log("Req options: ", requestOptions)
            return fetch(serverURL + '/online/leave', requestOptions)
        }).then((response: Response) => {
            this.handleGameInteraction(response)
        }).catch(() => {/* false */

        })
        logFirebaseEvent('leave_game', { gameID: gameId });
    };

    joinClassicGame(gameId: string, position: number) {
        getCache("token").then((value: string) => {
            if (value == '') {
                return Promise.reject("do not auth check an empty token")
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({ "gameId": gameId, "position": position,"type": this.props.item.gameType })
            };
            return requestOptions

        }).then((requestOptions) => {
            console.log("Req options: ", requestOptions)
            return fetch(serverURL + '/online/app/join', requestOptions)
        }).then((response: Response) => {
            this.handleGameInteraction(response)
        }).catch(() => {/* false */

        })
        logFirebaseEvent('join_classic_game', { gameID: gameId, position: position, type: this.props.item.gameType });
    };

    leaveClassicGame(gameId: string) {
        getCache("token").then((value: string) => {
            if (value == '') {
                return Promise.reject("do not auth check an empty token")
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({ "gameId": gameId, "type": this.props.item.gameType})
            };
            return requestOptions

        }).then((requestOptions) => {
            console.log("Req options: ", requestOptions)
            return fetch(serverURL + '/online/app/leave', requestOptions)
        }).then((response: Response) => {
            this.handleGameInteraction(response)
        }).catch(() => {/* false */

        })
        logFirebaseEvent('leave_classic_game', { gameID: gameId, type: this.props.item.gameType });
    };

    getPlayer(playerMeta, gameState, gameId, appGame, position) {
        let playerAction;
        var buttonTitle = 'Join';
        var username = playerMeta.username;
        var displayname = playerMeta.displayname;
        var buttonDisabled = playerMeta.spotOccupied;
        var playerTextStyle = styles.dominoPlayerText;
        var playerActionButton = (
            <Button
                onPress={() => this.joinGame(gameId, position)}
                title={buttonTitle}
                disabled={buttonDisabled}
                color={styleTheme.primaryButtonColor}
                accessibilityLabel="Join Domino Game" />
        )
        if (appGame){
            playerActionButton = (
                <Button
                    onPress={() => this.joinClassicGame(gameId, position)}
                    title={buttonTitle}
                    disabled={buttonDisabled}
                    color={styleTheme.primaryButtonColor}
                    accessibilityLabel="Join Domino Game" />
            )
        }
        if (playerMeta.username == this.state.username && playerMeta.username != "") {
            buttonDisabled = false;
            buttonTitle = 'Leave';
            playerActionButton = (
                <Button
                    onPress={() => this.leaveGame(gameId)}
                    title={buttonTitle}
                    disabled={buttonDisabled}
                    color={styleTheme.primaryButtonColor}
                    accessibilityLabel="Join Domino Game" />
            )
            if(appGame){
                playerActionButton = (
                    <Button
                        onPress={() => this.leaveClassicGame(gameId)}
                        title={buttonTitle}
                        disabled={buttonDisabled}
                        color={styleTheme.primaryButtonColor}
                        accessibilityLabel="Join Domino Game" />
                )
            }

        }
        if (playerMeta.username == '' && !playerMeta.spotOccupied) {
            username = 'click to join'
            playerTextStyle = styles.dominoPlayerTextJoin;
        }
        if (gameState == 'waiting') {
            playerAction = (
                <View style={styles.dominoPlayerButton}>
                    {playerActionButton}
                </View>
            )
        } else {
            playerAction = <Text numberOfLines={1} style={styles.dominoScoreText}>{playerMeta.wins}</Text>
        }
        return (
            (
                <View style={styles.dominoPlayer} >
                    <Image
                        style={styles.dominoPlayerPic}
                        source={{
                            uri: playerMeta.profilePicURL + "?" + now,
                        }}
                    />
                    <Text numberOfLines={1} style={playerTextStyle}>{displayname}</Text>
                    {playerAction}
                    <Text style={styles.errorText}>{this.state.errorText}</Text>
                </View>
            )
        )
    }

    getActionButton(gameState, playerInvolved) {
        if (gameState == 'running' && playerInvolved) {
            return (
                <View style={styles.dominoTableHolder}>
                    <View style={styles.playButton}>
                        <Button
                            onPress={() => this.playGame(this.props.item.id, this.props.item.gameType)}
                            title="Play"
                            color={styleTheme.primaryButtonColor}
                            accessibilityLabel="Click here to play" />
                    </View>
                </View>
            )
        } else if (gameState == 'running') {
            return (
                <View style={styles.dominoTableHolder}>
                    <View style={styles.playButton}>
                        <Button
                            onPress={() => this.spectateGame(this.props.item.id, this.props.item.gameType)}
                            title="Spectate"
                            color={styleTheme.primaryButtonColor}
                            accessibilityLabel="Click here to spected" />
                    </View>
                </View>
            )
        } else if (gameState == 'active' && playerInvolved) {
            return (
                <View style={styles.dominoTableHolder}>
                    <View style={styles.playButton}>
                        <Button
                            onPress={() => this.playClassicGame(this.props.item.id, this.props.item.gameType)}
                            title="Play"
                            color={styleTheme.primaryButtonColor}
                            accessibilityLabel="Click here to play" />
                    </View>
                </View>
            )
        } else if (gameState == 'active') {
            return (
                <View style={styles.dominoTableHolder}>
                    <View style={styles.playButton}>
                        <Button
                            onPress={() => this.spectateClassicGame(this.props.item.id, this.props.item.gameType)}
                            title="Spectate"
                            color={styleTheme.primaryButtonColor}
                            accessibilityLabel="Click here to spected" />
                    </View>
                </View>
            )
        } else {
            return (<View></View>)
        }

    }

    componentDidMount(): void {
        if (Platform.OS == "web") {
            const script = document.createElement("script");

            script.src = this.state.adSrc;
            script.async = true;

            document.body.appendChild(script);
        }
    }

    render() {
        var cutthroatDivider = (
            <View style={styles.dominoBorder}></View>
        )
        var appInfoMessage = (
            <View>
                <Text style={styles.newGameText}>This table uses the server that connects to the app you can find on the app store</Text>
            </View>
        )
        if (this.props.item.appGame == false){
            appInfoMessage = <View></View>
        }
        if (this.props.item.gameType == 'partner') {
            cutthroatDivider = <View></View>
        }
        if (this.props.item.type == "table") {
            return <NewGameCard navigation={this.props.navigation}></NewGameCard>
        } else if (this.props.item.type == "ad") {

            return this.state.ad;
        }

        let player1, player2, player3, player4;
        player1 = this.getPlayer(this.props.item.player1, this.props.item.gameState, this.props.item.id, this.props.item.appGame,0)
        player2 = this.getPlayer(this.props.item.player2, this.props.item.gameState, this.props.item.id, this.props.item.appGame,1)
        player3 = this.getPlayer(this.props.item.player3, this.props.item.gameState, this.props.item.id, this.props.item.appGame,2)
        player4 = this.getPlayer(this.props.item.player4, this.props.item.gameState, this.props.item.id, this.props.item.appGame,3)
        let actionButton = this.getActionButton(this.props.item.gameState, this.props.item.playerInvolved)
        return (
            <Card containerStyle={styles.dominoTable}>
                <Card.Title style={styles.newGameTitle}>{this.props.item.gameType}</Card.Title>
                {appInfoMessage}
                <Card.Divider />
                <View style={styles.dominoTableHolder}>
                    {player1}
                    {cutthroatDivider}
                    {player3}
                    <View style={styles.dominoBorder}></View>
                    {player2}
                    {cutthroatDivider}
                    {player4}
                </View>
                {actionButton}
            </Card>)
    }
}

