import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View, Image, Button, Text, Switch, FlatList } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { Card } from 'react-native-elements';
import LoginForm from './LoginForm'
import { styles, styleTheme } from "../themes/styles";
import { Icon } from 'react-native-elements'
import GameCanvas from '../game/GameCanvas'
import { storeCache, getCache } from '../utils/cacheStore'
import { serverURL } from '../constants/serverURL';
import PlayerRank from './PlayerRank';
import SwitchSelector from "react-native-switch-selector";

const options = [
    { label: "Cutthroat", value: "cutthroat", testID: "cutthroat", accessibilityLabel: "cutthroat" },
    { label: "Partner", value: "partner", testID: "partner", accessibilityLabel: "partner" },
];


export default class PlayerRankings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rankingList: [],
            refresh: false,
            mode: "cutthroat",
        }
        this.handleToggle = this.handleToggle.bind(this);
    }

    handleGetRankings(response) {
        if (response.status == 200) {
            response.json().then(data => this.updateRankingsList(data));
        }
    }

    updateRankingsList(rankingList) {
        var defaultTable = [
            {
                type: "controller",
            }
        ]
        for (var i = 0; i < rankingList.rankings.length; i++) {
            rankingList.rankings[i].position = i + 1;
            rankingList.rankings[i].id = i;
        }

        this.setState({
            rankingList: defaultTable.concat(rankingList.rankings),
            refresh: !this.state.refresh
        })
    }

    getRankingList() {
        getCache("token").then((value: string) => {
            if (value == '') {
                return Promise.reject("do not auth check an empty token")
            }
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({ "type": this.state.mode })
            };
            return requestOptions

        }).then((requestOptions) => {
            return fetch(serverURL + '/user/rankings', requestOptions)
        }).then((response: Response) => {
            this.handleGetRankings(response)
        }).catch((error) => {/* false */
            //console.log("error: ", error)
        })
    }

    renderTable({ item }, props) {
        if (item.type == "controller") {
            return (
                <Card containerStyle={styles.newGameCard}>
                    <Card.Title style={styles.newGameTitle}>Player Rankings</Card.Title>
                    <Card.Divider />

                    <SwitchSelector
                        buttonColor={"#268529"}
                        borderColor={"#268529"}
                        options={options}
                        initial={0}
                        onPress={this.handleToggle}
                    />
                    <Text>{'\n'}</Text>
                   
                    <Text style={styles.newGameText}>Players must have played a game within the least 14 days to show up in the rankings.</Text>
                    <Text style={styles.newGameText}>Winning a game against a player that is ranked higher will score you more points.</Text>
                    <Text style={styles.newGameText}>Losing when the game predicted that you would win will lose you points.</Text>
                </Card>
            )
        }
        return (
            <PlayerRank downloadList={this.getRankingList} navigation={props.navigation} item={item} />
        )
    }

    componentDidMount(): void {
        this.getRankingList()
        setInterval(() => this.getRankingList(), 60000)
    }

    handleToggle(value) {
        console.log("Value: ", value);
        this.setState({ mode: value });
        this.getRankingList();
    }


    render() {

        return (
            // <View style={styles.container} >
            //     <StatusBar style="auto" />
            //     <Card containerStyle={styles.newGameCard}>
            //             <Card.Title style={styles.newGameTitle}>Player Rankings</Card.Title>
            //             <Card.Divider />
            //     <View style={styles.startGameContainer} >

            <>



                <FlatList
                    style={styles.playContent}
                    data={this.state.rankingList}
                    renderItem={(item) => this.renderTable(item, this.props)}
                    keyExtractor={item => item.id}
                    extraData={this.state.refresh}
                    contentContainerStyle={{
                        flexGrow: 1,
                    }} />

            </>
            //     </View>
            //     </Card>
            // </View>
        )


    }
}

