import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View, Image, Button, TextInput } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import LoginForm from './LoginForm'
import {styles, styleTheme} from "../themes/styles"

export default class Login extends Component {
  constructor(props){
    super(props);
  }
  render() {
    return (<View style={styles.container} >

      <View style={styles.logoContainer}>
        <Image style={styles.logo} source={require("../../assets/jsd-logo.png")} />
      </View>

      <View style={styles.formContainer}>
        <LoginForm navigation={this.props.navigation}/>
      </View>
      
      <StatusBar style="auto" />
    </View>)
  }
}

