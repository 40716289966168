import React, { Component} from 'react';
import { Image, View, Platform, TouchableOpacity, Text, StyleSheet } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import ImageResizer from 'react-native-image-resizer';
import Resizer from "react-image-file-resizer";
import { storeCache, getCache } from '../utils/cacheStore';
import { serverURL } from '../constants/serverURL';


const Compress = require('compress.js');
var now = performance.now();

async function resizeImageFn(file) {

    const compress = new Compress();
    const resizedImage = await compress.compress([file], {
        size: 0.5, // the max size in MB, defaults to 2MB
        quality: 1, // the quality of the image, max is 1,
        maxWidth: 200, // the max width of the output image, defaults to 1920px
        maxHeight: 200, // the max height of the output image, defaults to 1920px
        resize: true // defaults to true, set false if you do not want to resize the image width and height
    })
    const img = resizedImage[0];
    const base64str = img.data
    const imgExt = img.ext
    const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt)
    return resizedFiile;
}

const fetchImageFromUri = async (uri) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
};

async function uploadPhoto(picture) {
    const data = new FormData();
    data.append('image', picture);
    var token = await getCache("token");
    let res = await fetch(
        serverURL + '/user/upload/picture',
        {
            method: 'post',
            headers: { 'jsd-auth': token },
            body: data,
        }
    );
    let responseJson = await res.json();
    console.log(res);
}


export default class UploadImage extends Component {
    constructor(props) {
        super(props);
        console.log("Props: ", props.image, "Props: ", props);
        this.state = {
            image: props.image,
            imageW: props.image,
        }
    }

    setImage(img) {
        if(this.state.imageW == img){
            return
        }
        var img2 = img;
        if(img.includes("http")){
            img = img + "?" + performance.now()
        }
     
        setTimeout(() => {     this.setState({
            image: img,
            imageW: img2,
        }) }, 1000);
    }


    addImage = async () => {
        let _image = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            aspect: [4, 4],
            quality: 0,
        });

        if (!_image.cancelled) {
            var img = await fetchImageFromUri(_image.uri);
            uploadPhoto(img);
            this.setImage(_image.uri);
        }
    };

  componentDidUpdate(){
    this.setImage(this.props.image);
  }

    render() {
        return (
            <View style={imageUploaderStyles.container}>
                {
                    this.state.image && <Image source={{ uri: this.state.image }} style={{ width: 200, height: 200 }} />
                }

                <View style={imageUploaderStyles.uploadBtnContainer}>
                    <TouchableOpacity onPress={this.addImage} style={imageUploaderStyles.uploadBtn} >
                        <Text>{this.state.image ? 'Edit' : 'Upload'} Image</Text>
                        <AntDesign name="camera" size={20} color="black" />
                    </TouchableOpacity>
                </View>


            </View>

        );
    }
}

const imageUploaderStyles = StyleSheet.create({
    container: {
        elevation: 2,
        height: 200,
        width: 200,
        backgroundColor: '#efefef',
        position: 'relative',
        borderRadius: 999,
        overflow: 'hidden',
    },
    uploadBtnContainer: {
        opacity: 0.7,
        position: 'absolute',
        right: 0,
        bottom: 0,
        backgroundColor: 'lightgrey',
        width: '100%',
        height: '25%',
    },
    uploadBtn: {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center'
    }
})