import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, View, Image, Button, Text, Switch, Platform } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { Card } from 'react-native-elements';
import LoginForm from './LoginForm'
import { styles, styleTheme } from "../themes/styles";
import { Icon } from 'react-native-elements'
import { storeCache, getCache } from '../utils/cacheStore'
import { serverURL } from '../constants/serverURL'
import { logFirebaseEvent } from '../utils/firebase';


function adStatusCallback(status) {
    console.log('Ad Status: ' + status);
}


var options = {
    zoneId: 5076,
    adStatusCb: adStatusCallback
};

export default class StartGameMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            difficultyHard: false,
            errorText: "",
        }
    }

    startGame(gameMode: string, randomSeed: number) {
        console.log("Starting game: ", gameMode);
        console.log("Platform: ", Platform.OS)
        if (Platform.OS == "web") {
            storeCache("dominoGameMode", gameMode)
            storeCache("connectionType", "local")
            storeCache("gameURL", "")
            storeCache("randomSeed", randomSeed)
            logFirebaseEvent('play_game', { dominoGameMode: gameMode, connectionType: "local", randomSeed: randomSeed });
            fetch(serverURL + '/user/play');
            this.props.navigation.navigate('GameScreen', { gameMode: gameMode, online: false })
            
        } else {
            storeCache("dominoGameMode", gameMode)
            storeCache("connectionType", "local")
            storeCache("gameURL", "")
            storeCache("randomSeed", randomSeed)
            logFirebaseEvent('play_game', { dominoGameMode: gameMode, connectionType: "local", randomSeed: randomSeed });
            fetch(serverURL + '/user/play');
            this.props.navigation.navigate('GameScreen', { gameMode: gameMode, online: false })
            
        }

    }

    handleCreateGameResponse(response) {
        if (response.status == 200) {

        } else if (response.status == 400) {
            this.setState({
                errorText: "Please Join the Game Below",
            })
            setTimeout(() => this.setState({
                errorText: "",
            }), 5000)
        }
        else {
            this.setState({
                errorText: "Error Creating game, please check your connection",
            })
            setTimeout(() => this.setState({
                errorText: "",
            }), 5000)
        }

    };

    createGame(gameType: string) {
        this.setState({ showForm: false });
        getCache("token").then((value: string) => {
            if (value == '') {
                return Promise.reject("do not auth check an empty token")
            }
            console.log("goat: ", value)
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({ "type": gameType, "isPrivate": false })
            };
            return requestOptions

        }).then((requestOptions) => {
            console.log("Req options: ", requestOptions)
            return fetch(serverURL + '/online/create', requestOptions)
        }).then((response: Response) => {
            this.handleCreateGameResponse(response)
        }).catch((err) => {/* false */
            console.log("Error: ", err)
        })
    };

    render() {

        return (<View style={styles.startGameContainer} >

            <View style={styles.startGameButton}>
                <Button
                    onPress={() => this.startGame("cutthroat", Math.floor(Math.random() * 10000000000))}
                    title="Play AI Cutthroat"
                    color={styleTheme.primaryButtonColor}
                    accessibilityLabel="Play AI Cutthroat" />
            </View>

            <View style={styles.startGameButton}>
                <Button
                    onPress={() => this.startGame("partner", Math.floor(Math.random() * 10000000000))}
                    title="Play AI Partner"
                    color={styleTheme.primaryButtonColor}
                    accessibilityLabel="Play AI Partner" />
            </View>
            {/* <View style={styles.startGameButton}>
                <Button
                    onPress={() => this.createGame("cutthroat")}
                    title="Create Online Cutthroat"
                    color={styleTheme.primaryButtonColor}
                    accessibilityLabel="Play Online Cutthroat" />

            </View>
            <View style={styles.startGameButton}>
                <Button
                    onPress={() => this.createGame("partner")}
                    title="Create Online Partner"
                    color={styleTheme.primaryButtonColor}
                    accessibilityLabel="PlayOnlinePartner" />
            </View> */}
            <Text style={styles.errorText}>{this.state.errorText}</Text>

        </View>)
    }
}

