const localURL: string = "http://localhost:3000";
const deployURL: string = "https://api.jamaicanstyledominoes.com"
const deployWSURL: string = "wss://api.jamaicanstyledominoes.com"
const localWSURL: string = "ws://localhost:3000"
const localFEURL: string = "http://localhost:3001"
const deployFEURL: string = "http://jamaicanstyledominoes.com"


// var serverURL: string = localURL;
// var serverWSURL: string = localWSURL;
// var feUrl: string = localFEURL;

var serverURL: string = deployURL;
var serverWSURL: string = deployWSURL;
var feUrl: string = deployFEURL;
export  {feUrl, serverURL, serverWSURL };