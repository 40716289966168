import React, { Component } from 'react';
import { ActivityIndicator,  Platform, View, Image, Button, Text, TextInput, TouchableHighlight } from 'react-native';
//import CheckBox from '@react-native-community/checkbox';
import { StatusBar } from 'expo-status-bar';
import { styles, styleTheme } from '../themes/styles'
import { darkStyleTheme } from '../themes/darkTheme';
import { storeCache, getCache } from '../utils/cacheStore'
import {serverURL} from '../constants/serverURL'
import { Icon } from 'react-native-elements'

function ValidateEmail(inputText) {
    console.log("Input text: ", inputText);
    var mailformat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (inputText.match(mailformat)) {
        return true;
    }
    else {
        return false;
    }
}

export default class ChangePasswordForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fullname: "",
            usernames: "",
            userPassword: "",
            confirmPassword: "",
            userEmail: "",
            showForm: true,
            agree: false,
            passwordStyle: styles.textInputIconStyle,
            passwordPlaceholder: "Password",
            showPassword: false,
            passwordIcon: "eye-slash",
            confirmPasswordStyle: styles.textInputIconStyle,
            confirmPasswordPlaceholder: "Confirm Password",
            showConfirmPassword: false,
            confirmPasswordIcon: "eye-slash",
            errorText: "",
            successText: "",
        }
    };

    componentDidMount() {
        console.log(this.props.activation_key)
    }

    setName(text: string) {
        this.setState({
            fullname: text
        });
    };

    setEmail(text: string) {
        this.setState({
            userEmail: text
        });
    };

    setUsername(text: string) {
        this.setState({
            username: text
        });
    };

    setConfirmPassword(text: string) {
        this.setState({
            confirmPassword: text
        });
    };

    setPassword(text: string) {
        this.setState({
            userPassword: text
        });
    };

    storeToken(loginResponse) {
        console.log(Platform.OS)
        storeCache('token', loginResponse.token)

    };

    handleResponse(response) {
        if (response.status == 200) {
            this.setState({ showForm: true });
            this.setState({
                successText: "Successfully changed user password",
                errorText: "",
            })
            setTimeout(()=> this.props.navigation.navigate('Login', {}), 5000)
        }
        else if (response.status == 401) {
            console.log("Unable to create user")
            this.setState({ showForm: true });
            this.setState({
                errorText: "Error resetting password.",
                successText: "",
            })
            setTimeout(() => this.setState({
                errorText: "",
                successText: "",
            }), 5000)
        } else {
            console.log("Unable to create user")
            this.setState({ showForm: true });
            this.setState({
                errorText: "Error resetting password",
                successText: "",
            })
            setTimeout(() => this.setState({
                errorText: "",
                successText: "",
            }), 5000)
        }
    };

    togglePasswordShow() {
        if (this.state.showPassword) {
            this.setState({ passwordIcon: "eye-slash", showPassword: false })
        } else {
            this.setState({ passwordIcon: "eye", showPassword: true })
        }
    };

    toggleConfirmPasswordShow() {
        if (this.state.showConfirmPassword) {
            this.setState({ confirmPasswordIcon: "eye-slash", showConfirmPassword: false })
        } else {
            this.setState({ confirmPasswordIcon: "eye", showConfirmPassword: true })
        }
    };

    changePassword() {
        if (this.state.userPassword.length < 6) {
            this.setState({
                errorText: "Password too short.",
            })
            return
        }

        if (this.state.userPassword != this.state.confirmPassword) {
            this.setState({
                errorText: "Confirmation password does not match.",
            })
            return
        }

        this.setState({ showForm: false });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                identifier: this.props.activation_key, password:
                    this.state.userPassword, email: this.state.userEmail, name: this.state.fullname
            })
        };
        fetch(serverURL + '/user/change-password', requestOptions)
            .then(response => this.handleResponse(response))
    };

    transitionToLogin() {
        this.props.navigation.navigate('Login', { navigation: this.props.navigation })
    };

    openTermsAndConditions() {

    };

    setToggleCheckBox(value) {
        this.setState({ agree: value })
    };

    renderForm() {

        return (<View>


            <View style={styles.textInputIconContainer}>
                <TextInput
                    onChangeText={text => this.setPassword(text)}
                    style={this.state.passwordStyle}
                    secureTextEntry={!this.state.showPassword}
                    autoCompleteType='password'
                    placeholderTextColor={styleTheme.placeholderTextColor}
                    placeholder={this.state.passwordPlaceholder} />

                <Icon style={styles.textInputIcon} onPress={() => this.togglePasswordShow()}
                    name={this.state.passwordIcon} type='font-awesome' color={styleTheme.placeholderTextColor} />

            </View>

            <View style={styles.textInputIconContainer}>
                <TextInput
                    onChangeText={text => this.setConfirmPassword(text)}
                    style={this.state.confirmPasswordStyle}
                    secureTextEntry={!this.state.showConfirmPassword}
                    autoCompleteType='password'
                    placeholderTextColor={styleTheme.placeholderTextColor}
                    placeholder={this.state.confirmPasswordPlaceholder} />

                <Icon style={styles.textInputIcon} onPress={() => this.toggleConfirmPasswordShow()}
                    name={this.state.confirmPasswordIcon} type='font-awesome' color={styleTheme.placeholderTextColor} />

            </View>


            <Button
                onPress={() => this.changePassword()}
                title="Change Password"
                color={styleTheme.primaryButtonColor}
                accessibilityLabel="Click here to change password" />

            <View style={styles.signupContainer}>
                <Text style={styles.genericText}>I dont want to reset</Text>
                <Text style={styles.buttonText} onPress={() => this.transitionToLogin()}>Go to Login</Text>
            </View>

            <View style={styles.signupContainer2}>
                <Text style={styles.successText}>{this.state.successText}</Text>
                <Text style={styles.errorText}>{this.state.errorText}</Text>
            </View>
        </View>)
    }

    renderSpinner() {
        return (
            <View style={styles.spinnerContainer}>
                <ActivityIndicator size="large" color={styleTheme.primaryButtonColor} />
            </View>
        )
    }

    render() {

        if (this.state.showForm) {
            return this.renderForm()
        } else {
            return this.renderSpinner()
        }

    }
}
