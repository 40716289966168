import React from 'react';

export default class AdComponent extends React.Component {
  componentDidMount () {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (err){
      console.log("Error trying to push ads: ", err)
    }
  }

render () {
    return (
        <ins className='adsbygoogle'
          style={{ display: 'block' }}
          data-ad-client='ca-pub-9390519061536184'
          data-ad-slot='3077616832'
          data-ad-format='auto'
          data-full-width-responsive="true" />
    );
  }
}
