import React, { Component } from 'react';
import { ActivityIndicator, StyleSheet, SafeAreaView, Image, Button, TextInput, View } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Login from './Login';
import SignUp from './SignUp';
import Play from './Play';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { styles, styleTheme } from "../themes/styles"
import { storeCache, getCache } from '../utils/cacheStore'
import { serverURL } from '../constants/serverURL'
import UserAccount from './UserAccount';
import PlayerRankings from './PlayerRankings';
import Game from './Game';
import GameMenu from './GameMenu';
import ChatWindow from './ChatWindow';
import GameLocal from './GameLocal';



const Tab = createMaterialTopTabNavigator();


export default class GameScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chatTitle: "Chat"
        }
        this.setChatTitle = this.setChatTitle.bind(this);
    }

    storeToken(loginResponse) {
        storeCache('token', loginResponse.token)
    };

    handleAuthCheckResponse(response) {
        if (response.status != 200) {
            this.setState({ showForm: true });
            console.log("Auth check failed: ", response);
            response.json().then(data => this.storeToken(""));
            this.props.navigation.navigate('Login', {})
            this.setState({
                errorText: "",
            })
        }
    };

    authCheck() {
        this.setState({ showForm: false });
        getCache("token").then((value: string) => {
            if (value == '') {
                return Promise.reject("do not auth check an empty token")
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({})
            };
            return requestOptions

        }).then((requestOptions) => {
            console.log("Req options: ", requestOptions)
            return fetch(serverURL + '/user/verify/token', requestOptions)
        }).then((response: Response) => {
            this.handleAuthCheckResponse(response)
        }).catch(() => {/* false */

        })

    };

    componentDidMount() {

        this.authCheck()

    }

    setChatTitle(title: string) {
        this.setState({
            chatTitle: title,
        });
    }

    render() {


        return (
            (<SafeAreaView style={styles.stickToTop}>
         
                <Tab.Navigator backBehavior="history"
                    style={styles.homeMenu}
                    barStyle={styles.tabNavContainer}
                    tabBarOptions={styleTheme.tabNavigation}
                >

                    <Tab.Screen name="Board" component={Game} />
                    {this.props.route.params.online == true &&
                        <Tab.Screen name={this.state.chatTitle}
                            children={() =>  <ChatWindow username={this.props.route.params.username} gameID={this.props.route.params.gameID} classicMode={this.props.route.params.classicMode}/>} />
                    }
                    <Tab.Screen name="Menu" component={GameMenu} />

                </Tab.Navigator>
             
            </SafeAreaView>
            )
        )
    }
}

