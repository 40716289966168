import React from 'react';
import {
    Platform,
    StyleSheet,
    Text,
    TouchableOpacity,
    Image,
    View,
} from 'react-native';

import { GiftedChat, Actions, Bubble, Send } from 'react-native-gifted-chat';
import { serverURL, serverWSURL } from '../constants/serverURL';
import { storeCache, getCache } from '../utils/cacheStore'
import { parseJwt } from '../utils/parseToken';
import ChatWindowHolder from './ChatWindowHolder';
import { ReactNotifications, Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';



function truncate(str, n) {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str;
};
export default class ChatWindow extends React.Component {
    constructor(props) {
        super(props);
        getCache("token").then((value: string) => {
            var user = parseJwt(value)
            this.setState({
                username: user.username
            })
        })


        console.log("GameID: ", this.props.gameID);
        var chatLink = "/online/chat/";
        if (this.props.classicMode == true){
            chatLink = "/online/app/chat/partner/";
        }
        var ws = new WebSocket(serverWSURL + chatLink + this.props.gameID);
        var pingInterval;
        ws.onopen = () => {
            // connection opened
            console.log("Successfully opened chat connection")
            //ws.send('connected');  // send a message
            pingInterval = setInterval(() => ws.send(""), 1000);
        };


        this.state = {
            messages: [],
            loadEarlier: true,
            typingText: null,
            isLoadingEarlier: false,
            ws: ws,
            unread: 0,
        };

        this._isMounted = false;
        this.onSend = this.onSend.bind(this);
        this.onReceive = this.onReceive.bind(this);
        this.resetChatNotification = this.resetChatNotification.bind(this);
        this._isAlright = null;

        var compon = this;
        var errorFunc;
        errorFunc = (e) => {
            console.log("Websocket error: ", e)
            clearInterval(pingInterval);
            var ws = new WebSocket(serverWSURL + chatLink + this.props.gameID);
            ws.onopen = () => {
                // connection opened
                console.log("Successfully re-opened chat connection on error")
                //ws.send('connected');  // send a message
                pingInterval = setInterval(() => ws.send(""), 1000)
            };

            ws.onmessage = (e) => {
                console.log("Received from chatter: ", e.data)

                e.data.text().then((value: string) => {
                    console.log("Received man: ", value)
                    var msg = JSON.parse(value);
                    this.onReceive(msg);
                })
            };

            ws.onerror = errorFunc;

            setTimeout(function () {
                compon.setState({
                    ws: ws
                })
            }, 1000);


        } 

        var closeFunc;
        closeFunc = (e) => {
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            clearInterval(pingInterval);
            var ws = new WebSocket(serverWSURL + chatLink + this.props.gameID);
            ws.onopen = () => {
                // connection opened
                console.log("Successfully re-opened chat connection on close")
                //ws.send('connected');  // send a message
                pingInterval = setInterval(() => ws.send(""), 1000)
            };

            ws.onmessage = (e) => {
                console.log("Received from chatter: ", e.data)

                e.data.text().then((value: string) => {
                    console.log("Received man: ", value)
                    var msg = JSON.parse(value);
                    this.onReceive(msg);
                })
            };

 
            ws.onerror = errorFunc;

            setTimeout(function () {
                console.log(this)
                compon.setState({
                    ws: ws
                })
            }, 1000);
        };
        ws.onerror = errorFunc;

        ws.onmessage = (e) => {
            console.log("Received from chatter: ", e.data)

            e.data.text().then((value: string) => {
                console.log("Received man: ", value)
                var msg = JSON.parse(value);
                this.onReceive(msg);
            })
        };

        ws.onclose = closeFunc;

    }

    componentWillMount() {

        this._isMounted = true;
        this.setState(() => {
            return {
                messages: [],
            };
        });
    }

    componentWillUnmount() {
        this._isMounted = false;

    }

    onSend(messages = []) {
        for (var i = 0; i < messages.length; i++) {
            var message = {
                messageData: messages[i].text,
                voiceData: "",
            }
            this.state.ws.send(JSON.stringify(message));
        }
        this.setState((previousState) => {
            return {
                messages: GiftedChat.append(previousState.messages, messages),
            };
        });

    }

    resetChatNotification() {
        this.setState({
            unread: 0,
        });
    }


    onReceive(msg) {

        if (msg.user === this.state.username) {
            return
        }

        if (msg.messageData == "" && msg.voiceData == "") {
            return
        }

        if (msg.messageData == "" && msg.voiceData != "") {
            msg.messageData = "<Voice notes not supported in this chat>"
        }

        var notifData = truncate(msg.messageData, 140);

        Store.addNotification({
            title: msg.user,
            message: notifData,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 2500,
                onScreen: false
            }
        });

        this.setState((previousState) => {
            return {
                messages: GiftedChat.append(previousState.messages, {
                    _id: Math.round(Math.random() * 1000000),
                    text: msg.messageData,
                    createdAt: new Date(),
                    user: {
                        _id: msg.id,
                        name: msg.user,
                        // avatar: 'https://facebook.github.io/react/img/logo_og.png',
                    },
                }),
            };
        });
    }


    renderBubble(props) {
        return (
            <Bubble
                {...props}
                wrapperStyle={{
                    left: {
                        backgroundColor: '#f0f0f0',
                    },
                    right: {
                        backgroundColor: '#268529',
                    }
                }}
            />
        );
    }

    componentDidMount(): void {
        console.log("Chat window mounted");
    }

    renderFooter(props) {
        if (this.state.typingText) {
            return (
                <View style={styles.footerContainer}>
                    <Text style={styles.footerText}>
                        {this.state.typingText}
                    </Text>
                </View>
            );
        }
        return null;
    }



    routerWillLeave(nextLocation) {
        return false;
    }

    render() {

        return (
            <>
                <GiftedChat
                    messages={this.state.messages}
                    onSend={this.onSend}
                    renderUsernameOnMessage={true}
                    isLoadingEarlier={this.state.isLoadingEarlier}
                    renderBubble={this.renderBubble}
                    user={{
                        _id: 1, // sent messages should have same user._id
                    }}
                />


            </>
        );
    }
}