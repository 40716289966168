import React, { Component } from 'react';
import { ActivityIndicator, Platform, StyleSheet, View, Image, Button, Text, TextInput, TouchableHighlight } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { styles, styleTheme } from '../themes/styles'
import { darkStyleTheme } from '../themes/darkTheme';
import { storeCache, getCache } from '../utils/cacheStore'
import { serverURL } from '../constants/serverURL'
import { Icon } from 'react-native-elements'

export default class PasswordResetForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userIdentity: "",
            userPassword: "",
            showForm: true,
            passwordStyle: styles.textInputIconStyle,
            passwordPlaceholder: "Password",
            showPassword: false,
            passwordIcon: "eye-slash",
            errorText: "",
            successText: "",
        }
    };

    setUsername(text: string) {
        this.setState({
            userIdentity: text
        });
    };

    storeToken(loginResponse) {
        storeCache('token', loginResponse.token)
    };

    handleResponse(response) {
        if (response.status == 200) {
            this.setState({ showForm: true });
            console.log("successfully logged in");
            response.json().then(data => this.storeToken(data));
            this.setState({
                errorText: "",
                successText: "Reset instructions were sent to your email"
            })
            setTimeout(() => {
                this.setState({
                    errorText: "",
                    successText: "",
                });
                this.props.navigation.navigate('Login', {})
            }, 5000)
        }
        else {
            this.setState({ showForm: true });
            this.setState({
                errorText: "Error resetting password, please try again later or email support@jamaicanstyledominoes.com",
            })
            setTimeout(() => this.setState({
                errorText: "",
                successText: "",
            }), 5000)
        }
    };


    togglePasswordShow() {
        console.log("PRESS");

        if (this.state.showPassword) {
            this.setState({ passwordIcon: "eye-slash", showPassword: false })
        } else {
            this.setState({ passwordIcon: "eye", showPassword: true })
        }
    };

    handleAuthCheckResponse(response) {
        if (response.status == 200) {
            this.setState({ showForm: true });
            console.log("successfully logged in");
            response.json().then(data => this.storeToken(data));
            this.props.navigation.navigate('HomeMenu', {})
            this.setState({
                errorText: "",
            })
        } else {
            this.setState({ showForm: true });
        }

    };

    authCheck() {
        this.setState({ showForm: false });
        getCache("token").then((value: string) => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'jsd-auth': value },
                body: JSON.stringify({})
            };
            return requestOptions

        }).then((requestOptions) => {
            console.log("Req options: ", requestOptions)
            return fetch(serverURL + '/user/verify/token', requestOptions)
        }).then((response: Response) => {
            this.handleAuthCheckResponse(response)
        })

    };

    resetPassword() {
        this.setState({ showForm: false });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ identifier: this.state.userIdentity, })
        };
        fetch(serverURL + '/user/reset-password', requestOptions)
            .then(response => this.handleResponse(response))
    };

    transitionToLogin() {
        this.props.navigation.navigate('Login', {})
    };



    componentDidMount() {

        this.authCheck()

    }

    renderForm() {
        return (<View>
            <TextInput
                onChangeText={text => this.setUsername(text)}
                placeholder="Email"
                autoCompleteType='username'
                placeholderTextColor={styleTheme.placeholderTextColor}
                style={styles.textInputStyle} />


            <Button
                onPress={() => this.resetPassword()}
                title="Reset Password"
                color={styleTheme.primaryButtonColor}
                accessibilityLabel="Click here to reset password" />


            <View style={styles.signupContainer2}>
                <Text>{<br></br>}</Text>
                <Text style={styles.genericText}>{<br></br>}I remember my password</Text>
                <Text style={styles.buttonText} onPress={() => this.transitionToLogin()}>{<br></br>}Go to Login</Text>
            </View>


            <View style={styles.signupContainer2}>
                <Text style={styles.errorText}>{this.state.errorText}</Text>
                <Text style={styles.successText}>{this.state.successText}</Text>
            </View>

        </View>)
    }

    renderSpinner() {
        return (
            <View style={styles.spinnerContainer}>
                <ActivityIndicator size="large" color={styleTheme.primaryButtonColor} />
            </View>
        )
    }

    render() {

        if (this.state.showForm) {
            return this.renderForm()
        } else {
            return this.renderSpinner()
        }

    }
}
