


const domain: string = 'https://jamaicanstyledominoes.com';

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;" + "domain=jamaicanstyledominoes.com";
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;" + "domain=api.jamaicanstyledominoes.com";
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;" + "domain=test.jamaicanstyledominoes.com";
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

function storeCache(key: string, value) {
    if (value == undefined) {
        return
    }
    setCookie(key, value, 90)
}

async function getCache(key: string): Promise<string> {
  var cookiePromise = new Promise<string>(function(resolve,reject){
    resolve(getCookie(key))
  })
  return cookiePromise
}

export { storeCache, getCache }